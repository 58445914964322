import React, { useContext } from "react"
import { Location } from "@reach/router"
import { Link } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import TeaserImage from "../atoms/teaser-image"
import Heading2 from "../atoms/heading-2"
import Heading3 from "../atoms/heading-3"
import ModalContext from "./modal-context"
import SearchWrapper from "./search-wrapper"

const Paragraph = styled.p`
  font-size: 100%;
  @media ${({ theme }) => theme.breakpoint.mobile} {
    font-size: ${({ type }) => (type === "small" ? "100%" : "110%")} !important;
  }
  @media ${({ theme }) => theme.breakpoint.desktop} {
    font-size: ${({ type }) =>
      type === "small" ? "72.25%" : "95%"} !important;
  }
  margin-bottom: 1.5em;
`

const StyledA = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.currentColours.fg};
`
const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.currentColours.fg};
`

const RichText = ({ data, intl, type }) => {
  const { locale } = intl
  const { json } = data
  const setModalOpen = useContext(ModalContext)
  const options = {
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <Heading2>{children}</Heading2>,
      [BLOCKS.HEADING_3]: (node, children) => <Heading3>{children}</Heading3>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0][1] === "[search_form]") {
          return (
            <Location>
              {({ location }) => {
                return <SearchWrapper location={location} fieldOnly />
              }}
            </Location>
          )
        }
        return <Paragraph type={type}>{children}</Paragraph>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node.json.target.fields
        if (file[locale].url) {
          return <TeaserImage src={file[locale].url} alt={title[locale]} />
        }
        if (file.fi.url) {
          return <TeaserImage src={file.fi.url} alt={title[locale]} />
        }
        return null
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        if (
          uri.includes("http") ||
          uri.includes("mailto:") ||
          uri.includes("tel:")
        ) {
          return (
            <StyledA href={uri} target="_blank">
              {children}
            </StyledA>
          )
        }
        if (uri.includes("#contact")) {
          return <StyledA onClick={() => setModalOpen(uri)}>{children}</StyledA>
        }
        if (uri.includes("#maintenance")) {
          return <StyledA onClick={() => setModalOpen(uri)}>{children}</StyledA>
        }
        return <StyledLink to={uri}>{children}</StyledLink>
      },
    },
  }
  return documentToReactComponents(json, options)
}

export default injectIntl(RichText)
