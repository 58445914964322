import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { shape, string } from "prop-types"

const propTypes = {
  fluid: shape().isRequired,
  alt: string,
}

const defaultProps = {
  alt: "",
}

const Image = styled(Img)`
  margin-bottom: 1.5em;
`
const TeaserImage = ({ fluid, alt }) => {
  return <Image itemProp="image" fluid={fluid} alt={alt} />
}

TeaserImage.propTypes = propTypes

TeaserImage.defaultProps = defaultProps

export default TeaserImage
